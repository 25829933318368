import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { css } from "glamor";
import BenAndJerryImage from "../assets/images/ben-and-jerry-logo.png";
import GoogleImage from "../assets/images/google-logo.png";

interface OtherProjectsType {}
const OtherProjects: React.FC<OtherProjectsType> = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    respondTo: "slider",
  };
  return (
    <div {...css({ width: "300px" })}>
      <Slider {...settings}>
        <div>
          <img
            src={BenAndJerryImage}
            width={200}
            alt="Ben and Jerry Logo"
          />
          <h3 {...css({ paddingLeft: "12px" })}>I dig Good Stuff</h3>
          <p {...css({ fontSize: "10px", padding: "12px" })}>
            The IDGS (I Dig Good Stuff) project was one of my proudest
            works. The Ben & Jerry’s Sourcing Stories site presented a
            series of beautifully animated and interactive stories
            narrating the origins of the ingredients used in Ben &
            Jerry’s delectable ice cream. It was programmed using
            Javascript and swiffy animations.
          </p>
        </div>
        <div data-link="https://hackerspace.kinja.com/google-brand-impressions-tool-monitor-and-measure-your-1589072052">
          <img src={GoogleImage} width={160} alt="Google Logo" />
          <h3 {...css({ paddingLeft: "12px" })}>
            Google Brand Impressions
          </h3>
          <div
            {...css({
              fontSize: "10px",
              display: "block",
              padding: "12px",
            })}
          >
            {" "}
            A tool to monitor brand online reputation. “An ingenious
            tool for tracking a brand’s presence on the web,” as well
            as an effort to “change something boring into something
            entertaining and interactive.” Using visually-appealing
            and easy-to-understand imagery. This project was developed
            for Google in conjunction with
            <a
              href="https://useallfive.com/"
              target="_blank"
              title=""
            >
              UA5
            </a>
            and Kevin Deng. We used Google Clojure / Javascript and
            Swiffy animations.
          </div>
        </div>

        <div data-link="https://chrome.google.com/webstore/detail/an-awesome-book/jcafjdhiidcpdgpdbpnllmpheogojkfl?hl=en-US">
          <img src={GoogleImage} width={160} alt="Google Logo" />
          <h3 {...css({ paddingLeft: "12px" })}>An Awesome Book</h3>
          <div
            {...css({
              fontSize: "10px",
              display: "block",
              padding: "12px",
            })}
          >
            An animated and interactive version of the book, as a
            chrome app extension. This project was developed for
            Google in conjunction with
            <a
              href="https://useallfive.com/"
              target="_blank"
              title=""
            >
              UA5
            </a>
            and Kevin Deng. We used Google Clojure / Javascript and
            Swiffy animations.
          </div>
        </div>
      </Slider>
    </div>
  );
};
export default OtherProjects;
