import { action, computed, makeObservable, observable } from "mobx";

const TYPE_TIME = 0.1;
const DELETE_TIME = 0.05;

class Typewriter {
  cursorPos: number; // position in current sentence (word/sentence being displayed)
  initialized: boolean;
  isDeleting: boolean;
  lastTimestamp: number;
  play: boolean;
  sequencePos: number; // postion in list of sentences
  txt: string;
  wordsSequences: string[];
  timing: number; // time for next play

  constructor(text?: string[]) {
    this.cursorPos = 0;
    this.initialized = false;
    this.isDeleting = false;
    this.lastTimestamp = 0;
    this.play = true;
    this.sequencePos = 0;
    this.txt = "";
    this.wordsSequences = [];
    this.timing = TYPE_TIME;
    this.init(text);

    makeObservable(this, {
      cursorPos: observable,
      goToNextItem: action,
      init: action,
      initialized: observable,
      isDeleting: observable,
      play: observable,
      print: action,
      removeLetterAtPos: action,
      restart: action,
      stop: action,
      txt: observable,
      wordsSequences: observable,
      text: computed,
    });
  }

  get text() {
    return this.txt;
  }

  init = (text?: string[]) => {
    if (text !== undefined) {
      this.wordsSequences = text;
    }
    this.render(0);
  };

  printLetterAtPos = (pos: number) => {
    this.txt =
      this.txt + this.wordsSequences[this.sequencePos].charAt(pos);
  };

  removeLetterAtPos = () => {
    const text = this.txt.split("");
    text.pop();
    this.txt = text.join("");
  };

  print = () => {
    if (
      this.cursorPos < this.wordsSequences[this.sequencePos].length &&
      this.isDeleting === false
    ) {
      // print letter at current cursor position
      this.printLetterAtPos(this.cursorPos);
      this.cursorPos = this.cursorPos + 1;
    } else {
      // delete letter at current cursor position
      this.isDeleting = true;
      this.timing = DELETE_TIME;
      this.removeLetterAtPos();
      this.cursorPos = this.cursorPos - 1;
      if (this.cursorPos === -1) {
        this.goToNextItem();
      }
    }
  };

  goToNextItem = () => {
    this.cursorPos = 0;
    this.sequencePos = this.sequencePos + 1;
    this.isDeleting = false;
    this.timing = TYPE_TIME;
    if (this.sequencePos > this.wordsSequences.length - 1) {
      this.restart();
    }
  };

  restart = () => {
    this.cursorPos = 0;
    this.sequencePos = 0;
    this.isDeleting = false;
  };

  render = (now: number) => {
    if (this.play === true) {
      // each this.timining seconds, perform task
      if (
        !this.lastTimestamp ||
        now - this.lastTimestamp >= this.timing * 1000
      ) {
        this.lastTimestamp = now;
        this.print();
      }
      if (typeof window !== "undefined") {
        window.requestAnimationFrame(this.render);
      }
    }
  };

  stop = () => {
    this.play = false;
  };

  resume = () => {
    this.play = true;
    this.render(this.lastTimestamp);
  };
}

export default Typewriter;
