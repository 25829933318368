import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import { defaultStyles } from "../libs/styles";
import Typewriter from "../libs/Typewriter";

export const typewriter = new Typewriter([
  "Stephani.",
  "a UI Engineer.",
  "an Application Developer.",
  "interested in developing innovative, cutting edge and real-time web applications.",
]);

interface TypedMessageType {}
const TypedMessage: React.FC<TypedMessageType> = observer(({}) => {
  return (
    <h1 {...css(defaultStyles.font.h1)}>
      <span>Hi, I am</span>
      <span {...css({ marginLeft: "12px" })}>{typewriter.text}</span>
    </h1>
  );
});
export default TypedMessage;
