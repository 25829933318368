import * as React from "react";
import { css } from "glamor";
import { defaultStyles } from "../libs/styles";
import { OutboundLink } from "gatsby-plugin-gtag";
interface PropType {
  children: any;
}

const Layout: React.FC<PropType> = ({ children }) => {
  return (
    <div
      {...css({
        height: "100%",
        position: "fixed",
        width: "100%",
        top: 0,
        bottom: 0,
        overflowY: "scroll",
        overflowX: "hidden",
        scrollBehavior: "smooth",
      })}
    >
      <div
        {...css({
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "black",
          width: "100%",
          flexWrap: "wrap",
        })}
      >
        <OutboundLink
          href="#about-me"
          title="UI Engineer in Los Angeles"
        >
          <span
            {...css(defaultStyles.font.body, {
              fontWeight: "bold",
              fontSize: "10px",
            })}
          >
            stephanimoroni.com
          </span>
        </OutboundLink>
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: "12px",
            justifyContent: "center",
            flexWrap: "wrap",
          })}
        >
          <OutboundLink
            href="#about-me"
            title="UI Engineer in Los Angeles"
          >
            About Me
          </OutboundLink>
          <OutboundLink
            href="#netflix"
            title="Senior Software Engineer at Netflix"
          >
            Netflix
          </OutboundLink>
          <a href="/#nike" title="Senior Software Engineer at Nike">
            Nike
          </a>
          <a href="/#yahoo" title="Applications Engineer at Yahoo">
            Yahoo!
          </a>
          <a href="/#yahoo-super-star" title="Yahoo Super Star">
            Yahoo! Super Star
          </a>
          <a href="/#ucla" title="Computer Science Degree from UCLA">
            UCLA
          </a>
          <a href="/#other" title="Other fun UI / App Projects">
            Others
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Layout;
