import * as React from "react";
import { parallax } from "../libs/styles";
import { css } from "glamor";

interface BodyWrapperType {
  image: any;
  width?: number;
  id: string;
}
const BodyWrapper: React.FC<BodyWrapperType> = ({
  image,
  width = 800,
  children,
  id,
}) => {
  const w = `${width}px`;
  if (image === undefined) {
    return (
      <div
        id={id}
        {...css({
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <div {...css({ width: w })}>{children}</div>
      </div>
    );
  }
  return (
    <div
      id={id}
      {...css(parallax(image), {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <div {...css({ width: w, padding: "24px" })}>{children}</div>
    </div>
  );
};
export default BodyWrapper;
