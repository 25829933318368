import React from "react";
import { css } from "glamor";

interface SectionType {
  title: string;
  color?: string;
}
const Section: React.FC<SectionType> = ({
  title,
  children,
  color = "white",
}) => {
  return (
    <div {...css({ marginTop: "24px", color })}>
      <h2
        {...css({
          fontWeight: "bold",
          lineHeight: 0,
          fontSize: "14px",
          color,
        })}
      >
        {title}
      </h2>
      {children}
    </div>
  );
};
export default Section;
