import * as React from "react";
import Head from "../components/Head";
import Layout from "../components/layout";
import TypedMessage from "../components/TypedMessage";
import { defaultStyles } from "../libs/styles";
import { css } from "glamor";
import StephImage from "../assets/images/steph.jpg";
import BearImage from "../assets/images/audiobear.jpg";
import UclaImage from "../assets/images/ucla.png";
import PalmTreesImage from "../assets/images/palmtrees.jpg";
import NeftlixImage from "../assets/images/netflixlogo.png";
import NikeLogo from "../assets/images/nikelogo.png";
import NikeRunning from "../assets/images/nike-run-club.jpg";
import UCLALogo from "../assets/images/ucla-logo.png";
import YahooImage from "../assets/images/yahoobg2.jpg";
import SuperStarImage from "../assets/images/stephani-bishop-katie-couric-bw.jpg";
import BodyWrapper from "../components/BodyWrapper";
import YahooLogo from "../assets/images/yahoologowhite.png";
import Section from "../components/Section";
import OtherImage from "../assets/images/bg2.jpg";
// import SuperStarMovie from "../assets/yahoosuperstar.mp4";
import PosterImage from "../assets/images/poster.png";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import OtherProjects from "../components/OtherProjects";
import HeadsetIcon from "@material-ui/icons/Headset";
import Popups from "../components/Popups";
import AppStore from "../store/AppStore";

const store = new AppStore();

css.global("body", {
  height: "100%",
  width: "100%",
  padding: 0,
  margin: 0,
});

css.global("a", {
  ...defaultStyles.font.body,
  fontSize: "10px",
  textTransform: "uppercase",
  cursor: "pointer",
  textDecoration: "none",
  fontWeight: "bold",
});

css.global("html", {
  height: "100%",
  width: "100%",
  padding: 0,
  margin: 0,
});

// Scrollbar
css.global("::-webkit-scrollbar", {
  width: "10px",
  overflow: "overlay",
  display: "none",
});

// track
css.global("::-webkit-scrollbar-track", {
  background: "transparent",
});

// handle
css.global("::-webkit-scrollbar-thumb", {
  background: "transparent",
  borderRadius: "5px",
});

// handle on hover
css.global("::-webkit-scrollbar-thumb:hover", {
  background: "black",
});

css.global("::-webkit-scrollbar-corner", {
  background: "transparent",
});

export default function Home() {
  return (
    <Layout>
      <Head
        title={"Stephani Moroni Bishop - Senior Software UI Engineer"}
        keywords={[
          "engineer",
          "female",
          "senior",
          "UI",
          "react",
          "typescript",
          "OOP",
          "mobx",
          "observables",
          "css-in-js",
          "glamour",
          "emotion",
        ]}
        description="I am a Senior Software UI Engineer, an application developer
        and interested in developing innovative, cutting edge and
        real-time web applications."
      />
      <BodyWrapper id="about-me" image={StephImage}>
        <TypedMessage />
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          I am a senior software UI engineer, an application developer
          and interested in developing innovative, cutting edge and
          real-time web applications.
        </div>
      </BodyWrapper>

      <BodyWrapper id="netflix" image={BearImage}>
        <img src={NeftlixImage} alt="Netflix Logo" width={200} />
        {/* <h1 {...css(defaultStyles.font.h1)}>Take 1. Take 2. Take 3.</h1> */}
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <p>
            I am currently a Senior Software (UI) Engineer at{" "}
            <span {...css({ fontWeight: "bold" })}>Netflix</span>.
          </p>
          <p>
            I work in the Animation Studio. For my current project, I
            am primarily focused on real-time audio based tools. My
            day-to-day activities involve coding with technologies
            such as WebRTC, Web Audio API,{" "}
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API"
              title="Media Stream API"
            >
              Media Stream API
            </a>
            ,
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API"
              title="Media Recording API"
            >
              {" "}
              MediaStream Recording API
            </a>
            , Speech APIs, Audio Streaming, Mobx, React, Typescript,
            CSS-in-JS etc...
          </p>
          <Section title="Tech Stack">
            <p>
              Typescript, React, Mobx, Director, WebRTC, Web Audio
              API, Web Speech API, Audio Streaming, Pro Tools, Media
              Composer, websockets, observables, CSS-in-JS, Glamor,
              Emotion, NextJs, CRA... etc
            </p>
          </Section>
          <Section title="Products">
            <p>
              Recording, Casting, Content Hub, Subtitling Originator
            </p>
          </Section>

          <a
            href="/booth/recording"
            {...css(defaultStyles.button.primary, {
              display: "inline-flex",
              alignItems: "center",
              gridGap: "4px",
            })}
          >
            <HeadsetIcon />
            Open recording booth
          </a>
        </div>
      </BodyWrapper>

      <BodyWrapper id="nike" image={NikeRunning}>
        <img src={NikeLogo} alt="Nike Logo" width={200} />
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <h1>Just do it!</h1>
          <p>
            My time at Nike almost feels like a dream that never
            happened. Everything about that place is surreal. Their
            campus is massive, the facilities are breathtaking. The
            Nike campus is out of this world. Their events are on
            another level. Everything is extremely professional and
            built with high standards. Working at Nike is inspiring.
          </p>
          <p>
            At Nike I worked primarily on the checkout team for
            nike.com. I couldn't help but purchase many Nike products
            everyday. Hey, I was just testing.
          </p>
          <p>
            I also briefly worked on the SNKRs team, reusable
            components and on web accessibility for nike.com, giving
            training on web accessibility to my peers.
          </p>

          <Section title="Tech Stack">
            <p>
              Javascript, React, Redux, CSS-in-JS, WAI-ARIA, WCAG,
              Jaws, VoiceOver, Screen Readers, RxJs
            </p>
          </Section>

          <Section title="Product">
            <p>nike.com checkout</p>
          </Section>
        </div>
      </BodyWrapper>

      <BodyWrapper id="yahoo" image={YahooImage}>
        <img src={YahooLogo} alt="Yahoo Logo" width={200} />
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <p>
            At Yahoo! I grew, I learned, I won, I conquered, I
            invented, I innovated, I thrived.{" "}
          </p>
          <p>
            For one of my projects during my time at Yahoo!, I worked
            closely with the visually impaired. That allowed me to
            have remarkable and very valuable experiences that I
            cherish to this day. I led numerous user studies where I
            watched how visually impaired users interacted with our
            product. Because of that, I was able to have a better
            understanding of their daily pain and was able to create a
            better and much more improved user experience for our
            products. Not only for visually impaired users, but to all
            users. Empathy is key.{" "}
          </p>

          <Section title="Tech Stack">
            <p>
              WAI-ARIA, WCAG, Jaws, VoiceOver, Screen Readers, Redux,
              React, Plain Javascript, YUI, CSS, SCSS, SASS, HTML/JSX
            </p>
          </Section>
          <Section title="Products">
            <p> Yahoo! Mail & Yahoo! Messenger</p>
          </Section>
          <Section title="Awards">
            <p>Yahoo! Super Star and many hack days winner</p>
          </Section>
          <Section title="Patent">
            <>
              <p>
                Co-Inventor with Mohit Goenka: Webpage navigation
                utilizing audio commands - Patent issuer and number US
                US20170098448A1
              </p>
              <a
                href="https://patents.google.com/patent/US20170098448A1/"
                target="_blank"
                {...css({ fontWeight: "bold", color: "white" })}
              >
                See patent
              </a>
            </>
          </Section>
        </div>
      </BodyWrapper>

      <BodyWrapper id="yahoo-super-star" image={SuperStarImage}>
        <img
          src={YahooLogo}
          alt="Stephani Bishop holding super star trophy with katie couric"
          width={200}
        />
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <h1>Super Star Award</h1>
          <p>
            Re-live this amazing moment with me. Watch Jeff Bonforte,
            VP of Yahoo! (at the time of this award), deliver an
            entertaining speech, followed by a clip containing some of
            my experiences at Yahoo! and finally, my super nervous
            speech.{" "}
          </p>
          {/* <video
            {...css({ border: "8px solid white" })}
            controls
            width={280}
            poster={PosterImage}
          >
            <source src={SuperStarMovie} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        </div>
      </BodyWrapper>

      <BodyWrapper id="#location" image={PalmTreesImage}>
        <h1 {...css(defaultStyles.font.h1)}>
          Based in Los Angeles, CA.
        </h1>
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          Although most part of my software engineering life has been
          in the Bay Area, I am currently based in{" "}
          <span {...css({ fontWeight: "bold" })}>
            Hollywood, Los Angeles, CA{" "}
          </span>{" "}
          and hopefully not leaving any time soon.{" "}
        </div>
      </BodyWrapper>
      <BodyWrapper id="ucla" image={UclaImage}>
        <h2 {...css(defaultStyles.font.h1)}>Graduation</h2>
        <img src={UCLALogo} alt="Ucla Logo" width={200} />
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <p>
            Among top 5 public universities in the US over many
            consecutive years
          </p>
          <p>UCLA Samueli School of Engineering</p>
          <p>UCLA Bachelor of Science</p>
          <Section title="Field of Study">
            <p>Computer Science</p>
          </Section>
          <Section title=" Activities and Societies">
            <p>
              Upsilon Pi Epsilon, CEED 2012 - Vice President of
              Upsilon Pi Epsilon, Computer Science Honor Society @
              UCLA California Beta Chapter. 2011 - Publicity Chair of
              Upsilon Pi Epsilon, Computer Science Honor Society @
              UCLA California Beta Chapter.
            </p>
          </Section>
        </div>
      </BodyWrapper>

      <BodyWrapper id="other" image={OtherImage}>
        <h2 {...css(defaultStyles.font.h1)}>Others</h2>

        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <p>
            There are so many other projects I worked on that it feels
            almost impossible (too lazy) to list them all. But here
            are some fun ones worth mentioning.
          </p>

          <OtherProjects />

          <p>
            For a more comprehensive list, visit my{" "}
            <a
              {...css({
                display: "inline",
                margin: 0,
                padding: 0,
                marginLeft: "10px",
              })}
              href="https://www.linkedin.com/in/stephani-moroni-bishop-91388410/"
              title="Visit Stephani Bishop's LinkedIn Profile"
            >
              <LinkedInIcon style={{ color: "white" }} />
            </a>{" "}
            profile
          </p>
        </div>
      </BodyWrapper>
      <div
        style={{
          height: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(19 19 19)",
        }}
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          })}
        >
          <span
            {...css(defaultStyles.font.body, { fontSize: "10px" })}
          >
            Stephani Moroni Bishop | Senior Software UI Engineer |
            stephanimoroni.com
          </span>
          <a
            href="mailto:me@stephanimoroni.com"
            title="Email Stephani Bishop"
          >
            <EmailIcon style={{ color: "white" }} />
          </a>

          <a
            href="https://www.linkedin.com/in/stephani-moroni-bishop-91388410/"
            title="Visit Stephani Bishop's LinkedIn Profile"
          >
            <LinkedInIcon style={{ color: "white" }} />
          </a>
        </div>
        <Popups store={store} />
      </div>
    </Layout>
  );
}
