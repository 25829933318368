import React from "react";
import { observer } from "mobx-react";
import { Box } from "@material-ui/core";
import AppStore, { PopupOption } from "../store/AppStore";
import RecordingBooth from "./RecordingBooth";

interface PopupsType {
  store: AppStore;
}
const Popups: React.FC<PopupsType> = observer(({ store }) => {
  const showRecordingBooth =
    store.currentPopup === PopupOption.recordingBooth;

  return (
    <Box>
      {showRecordingBooth && <RecordingBooth store={store} />}
    </Box>
  );
});
export default Popups;
